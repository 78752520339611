@import '../../../../../../styles/fonts/responsivity/breakpoints.scss';
.ht-list {
  @include large-only {
    width: 23%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 2rem;
  }
  @include small-and-medium-only {
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 16rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  @include medium-only {
    margin-right: 7rem;
    margin-left: 5rem;
  }
}
