.book-fullscreen-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;

  &__close-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    top: 3.125rem;
    margin-right: 23.75rem;

    button {
      background: none;
      padding: 0;
    }
  }
}
