@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.key-people-card {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 21rem;
  padding: 0 1rem;
  margin-bottom: 3rem;
  overflow: hidden;

  @include large-only {
    width: 25%;
  }
  @include medium-only {
    width: 33%;
  }
  @include small-only {
    width: 50%;
  }

  &__avatar {
    width: 11rem;
    height: 11rem;
    border-radius: var(--nj-semantic-size-border-radius-none);
    border-radius: 50%;
    object-fit: cover;
  }

  h6 {
    white-space: nowrap;
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0;
    min-height: 2.5rem;
    text-align: center;
    color: var(--nj-semantic-color-text-neutral-inverse-default);
  }

  p {
    height: 3rem;
    text-align: center;
    color: var(--nj-semantic-color-text-neutral-inverse-default);
  }

  &__button {
    position: absolute;
    bottom: 0;
  }
}
