@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ppvob-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ppvob-size {
  width: 80%;
}

:root {
  --height-small-pictures: 11.25rem;
}

@include mid-small-only {
  :root {
    --height-small-pictures: 8rem;
  }
}

.ppvob-title {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: #182663;
  text-align: center;
}

.ppvob-container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.ppvob-list-videos {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.ppvob-caption {
  position: relative;
  cursor: pointer;
  width: 100%;

  img {
    width: 100%;
    height: var(--height-small-pictures);
  }

  margin: 2rem 0rem;
}

.ppvob-caption-image {
  object-fit: cover;
}

.ppvob-caption--active img {
  filter: brightness(0.31);
  transition: filter 400ms ease-in-out;
}

.ppvob-animation-watch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--height-small-pictures);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ppvob-container-animation-video {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 4rem;

  span {
    display: flex;
    width: 0.5rem;
    height: 3rem;
    background-color: #fff;
    margin: 0.5rem;
    border-radius: 1rem;
  }

  @keyframes playedVideo1 {
    0% {
      height: 3rem;
    }

    50% {
      height: 1rem;
    }

    100% {
      height: 3rem;
    }
  }

  @keyframes playedVideo2 {
    0% {
      height: 1rem;
    }

    50% {
      height: 3rem;
    }

    100% {
      height: 1rem;
    }
  }
}

.ppvob-animated-white-bar-1 {
  animation: playedVideo1 4s infinite 0s;
  animation-play-state: var(--white-bar-animation-state);
}

.ppvob-animated-white-bar-2 {
  animation: playedVideo2 4s infinite 0s;
  animation-play-state: var(--white-bar-animation-state);
}

.ppvob-animated-white-bar-3 {
  animation: playedVideo1 4s infinite 0s;
  animation-play-state: var(--white-bar-animation-state);
}

.ppvob-video-caption-title {
  margin-top: 0.5rem;
}

.ppvob-button-video {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-right: 4rem;
}