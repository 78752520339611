.book-page {
  display: flex;

  &:is(:first-child) {
    justify-content: center;
    width: 100%;
  }

  &:is(:last-child) {
    justify-content: center;
    width: 100%;
  }

  &:not(:first-child):not(:last-child):nth-of-type(odd) {
    justify-content: flex-start;
  }

  &:not(:first-child):not(:last-child):nth-of-type(even) {
    justify-content: flex-end;
  }

  &__container {
    display: flex;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      user-select: none;
      pointer-events: none;
    }

    a {
      position: absolute;
      z-index: 99;
    }
  }
}
