@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ppkoc-keypoint {
    flex: 0 1 21%;
    margin-bottom: 4rem;
}

.ppkoc-icon img {
    width: auto;
    height: 3rem;
}

.ppkoc-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.ppkoc-description {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    p {
        max-width: 12rem;
    }
}