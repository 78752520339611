
.mpps-selector-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.mpps-selector-layout {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mpps-selector {
    width: 35rem;

    div {
        label {
            display: none;
        }

        input {
            color: var(--nj-core-color-grey-600);
        }
    }
}

.mpps-text {
    margin-top: 0.5rem;
    color: var(--nj-core-color-grey-600);
}

.mpps-selectable {
    --nj-list-deprecated-item-box-shadow: none !important;
    font-weight: normal;
}

.mpps-program {
    font-weight: bold;
}

.mpps-offset-project {
    padding-left: 3rem !important;
}

.mpps-offset-subproject {
    padding-left: 5rem !important;
}