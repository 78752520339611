@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
.his-layout {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include large-only {
    margin-bottom: 0;
  }
  @include small-and-medium-only {
    margin-bottom: 3rem;
  }
}

.his-title {
  margin-bottom: 1rem;
}

.his-content {
  margin-bottom: 0.5rem;
}
