@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
:root {
  --width-viva-enage-block: 40%;
  --width-video-block: 60%;
}
@include small-and-medium-only {
  :root {
    --width-viva-enage-block: auto;
    --width-video-block: auto;
  }
}

.ago-community-block-container {
  width: 100%;
  height: 100%;
  background: var(--nj-semantic-color-background-brand-tertiary-default);
  display: flex;

  .ago-video-community {
    margin-left: 6rem;
    margin-top: 2rem;
    margin-right: 1rem;
    width: var(--width-video-block);
  }

  .ago-social-media {
    margin-top: 2rem;
    margin-right: 6rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    width: var(--width-viva-enage-block);
  }
}

@include small-and-medium-only {
  .ago-community-block-container {
    display: flex;
    flex-direction: column;

    .ago-video-community {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .ago-social-media {
      margin-top: 0;
      margin-right: 6rem;
      margin-left: 6rem;
      margin-bottom: 1rem;
      height: 45rem;
    }
  }
}
