.vertical-stepper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 90%;
  margin: 2rem auto;
  padding: 4rem 1.5rem;
  border-left: 0.188rem solid var(--nj-semantic-color-border-neutral-strong-default);
  background: var(--nj-semantic-color-background-neutral-tertiary-default);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Lato;
  font-size: 3.375rem;
  font-style: normal;

  &__title {
    color: var(--nj-semantic-color-text-additional-signature-default);
    font-size: 3.375rem;
    font-weight: 700;
    text-align: center;
  }

  &__description {
    margin: 0 4rem;
    font-size: 1rem;
    text-align: center;
  }

  &__button {
    margin-left: auto;
    width: fit-content;
  }
}
