.ago-background {
  background-color: #f6f8f9;
}

.ago-top-news-grid {
  margin-bottom: 2rem;
}

.ago-first-video-container {
  grid-area: video;
}

.ago-viva-engage-container {
  grid-area: yammer;
}

.ago-digest-container {
  grid-area: digest;
}

.ago-grid-video {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 4rem;
  grid-template-areas:
    'video video yammer'
    'video video yammer'
    'video video yammer'
    'video video yammer'
    'video video yammer'
    'video video digest';
  column-gap: 2rem;
  row-gap: 2rem;
}

.ago-digest-container {
  background-image: url('../../images/png/stub/vid-third.png');
}

.ago-digest-container .white-high-title {
  padding: 2rem 4rem 1rem 4rem;
  color: var(--neutral-colors-white, #fff);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Lato;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.75rem;
  text-transform: uppercase;
}

.ago-digest-container .white-big-content {
  padding: 0rem 4rem 2rem 4rem;
  color: var(--neutral-colors-white, #fff);
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Lato;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}

.ago-iframe-ve {
  border: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 40rem;
}

.principal-video {
  grid-area: principal;
  background: beige;
}

.second-video {
  grid-area: second;
  background: beige;
}

.third-video {
  grid-area: third;
  background: beige;
}

.grid-video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'principal principal'
    'principal principal'
    'second third';
  column-gap: 2rem;
  row-gap: 2rem;
}

.container-button {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2rem;
}
