@import '../../../../styles/fonts/responsivity/breakpoints.scss';
.home-block-full-size {
  --news-block-raw-height: 100dvh;
  --ago-navbar-height: 6rem;
  --full-height: calc(var(--news-block-raw-height) - var(--ago-navbar-height));
  height: var(--full-height);
}

@include small-and-medium-only {
  .home-block-full-size {
    height: auto;
  }
}
