.eab-icon {
  color: white !important;
}

.eab-icon-reverse {
  color: var(--nj-core-color-grey-800) !important;
  svg path{
    fill: var(--nj-core-color-grey-800) !important;
    color: var(--nj-core-color-grey-800) !important;
  }
}

.eab-button-content {
  display: flex;
  align-items: center;
}

.eab-icon-outlook {
  margin-right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
