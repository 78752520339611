@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ippb-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0rem;
}

.ippb-box {
    @include medium-and-large-only {
        width: 80%;
    }
    @include small-only {
        width: 90%;
    }
    padding-top: 6rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: var(--nj-core-color-blue-900);
    color: var(--nj-core-color-grey-0);
    height: fit-content;
}

.ippb-box-image-bottom-margin {
    padding-bottom: 37rem;
    margin-bottom: -34rem;
}

.ippb-box-no-image-margin {
    padding-bottom: 6rem;
}

.ippb-image {
    display: flex;
    flex-direction: row;
    height: 40rem;
    @include medium-and-large-only {
        width: 66%;
    }
    @include small-only {
        width: 76%;
    }
    justify-content: center;
    div {
        height: 100%;
        width: 100%;
    }
}

.ippb-text-layout {
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.ippb-title {
    font-weight: bold;
    margin-bottom: 1rem;
}

.ippb-description {
    color: var(--nj-core-color-grey-0);
    font-weight: lighter;
}

.ippb-button-layout {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3rem;
    justify-content: flex-end;
}

.ippb-button {
    color: var(--nj-core-color-grey-0);
    .material-icons {
        color: var(--nj-core-color-grey-0);
    }
}

.ippb-button-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;
}

.ippd-button-icon {
    height: 2rem;
}