@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
.het-direction {
  @include large-only {
    display: flex;
    flex-direction: row;
  }
  @include small-and-medium-only {
    display: flex;
    height: auto;
    flex-direction: column;
  }
}

.het-margin {
  @include large-only {
    padding: 2rem 0rem 3rem 6rem;
  }
  @include small-and-medium-only {
    padding: 2rem 0rem 0rem 2rem;
  }
}

.het-event-title {
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.het-button-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  @include large-only {
    margin-right: 6.5rem;
  }
  @include small-and-medium-only  {
    margin-right: 2rem;
  }
}
