@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ddocpp-document {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ddocpp-document-layout {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 0.06rem solid var(--nj-core-color-grey-300);
  margin-bottom: 1rem;
}

.ddocpp-document-data-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  width: 65%;
}

.ddocpp-document-title-and-tag-layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
}

.ddocpp-document-tag {
  width: fit-content;
}

.ddocpp-document-title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  width: fit-content;
  white-space: nowrap;
}

.ddocpp-button-content {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: fit-content;
  max-width: 100%;
}

.ddocpp-button-icon {
  height: 1.5rem;
}

.ddocpp-document-description {
  margin-bottom: 1rem;
}

.ddocpp-document-date {
  color: var(--nj-core-color-grey-600);
  font-size: 0.875rem;
}

.ddocpp-document-button-layout {
  width: fit-content;
  max-width: 30%;
}

.ddocpp-button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: max-content;
}