@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.vertical-stepper-steps {
  display: flex;
  flex-direction: column;

  &__step {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 1.5rem 0;
    margin: 0 4rem 0 0;
    border-bottom: 0.063rem solid var(--nj-semantic-color-border-neutral-subtle-default);

    &:first-child {
      padding: 0 0 1.5rem 0;
    }
    &:last-child {
      padding: 1.5rem 0 0 0;
      border-bottom: none;
    }

    @include small-and-medium-only {
      margin: 0 4rem;
      flex-direction: column;
      gap: 2rem;
    }

    &__tail {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35%;
      min-width: 13.75rem;
      padding: 0 2rem;
      box-sizing: border-box;
      gap: 1rem;
      text-align: center;
      color: var(--nj-semantic-color-text-additional-signature-default);

      @include small-and-medium-only {
        width: 100%;
      }

      &__index {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0.188rem solid var(--nj-semantic-color-border-neutral-strong-default);
        font-size: 2.5rem;
        font-weight: 700;
      }

      &__title {
        font-size: 1.5rem;
        font-weight: 700;
      }

      &__subtitle {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    &__body {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      width: 65%;

      @include small-and-medium-only {
        justify-content: center;
        width: 100%;
      }
    }
  }
}
