.news-card {
  cursor: pointer;
  height: var(--ago-news-container-height);
  margin-bottom: 0rem;
  border: 0.063rem solid var(--nj-semantic-color-border-neutral-subtle-default);
}

.card-container {
  display: flex;
  padding: 2.5rem var(--nj-semantic-size-spacing-24);
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 0 0;
}

.ago-restriction-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card--vertical-position {
  margin: 2rem;
  img {
    height: 7rem;
  }

  .ago-restriction-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
