@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
:root {
  --height-small-pictures: 11.25rem;
}
@include mid-small-only {
  :root {
    --height-small-pictures: 8rem;
  }
}

.ago-title-video-community {
  margin-bottom: 1.5rem;
}

.ago-video-player-container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  .ago-list-videos {
    display: flex;
    flex-direction: row;

    .video-caption-image {
      object-fit: cover;
    }

    .video-caption--active img {
      filter: brightness(0.31);
      transition: filter 400ms ease-in-out;
    }

    .ago-animation-watch {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--height-small-pictures);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .ago-container-animation-video {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        height: 4rem;

        span {
          display: flex;
          width: 0.5rem;
          height: 3rem;
          background-color: #fff;
          margin: 0.5rem;
          border-radius: 1rem;
        }

        .ago-animated-white-bar-1 {
          animation: playedVideo1 4s infinite 0s;
          animation-play-state: var(--white-bar-animation-state);
        }

        .ago-animated-white-bar-2 {
          animation: playedVideo2 4s infinite 0s;
          animation-play-state: var(--white-bar-animation-state);
        }

        .ago-animated-white-bar-3 {
          animation: playedVideo1 4s infinite 0s;
          animation-play-state: var(--white-bar-animation-state);
        }

        @keyframes playedVideo1 {
          0% {
            height: 3rem;
          }
          50% {
            height: 1rem;
          }

          100% {
            height: 3rem;
          }
        }

        @keyframes playedVideo2 {
          0% {
            height: 1rem;
          }
          50% {
            height: 3rem;
          }

          100% {
            height: 1rem;
          }
        }
      }
    }

    .video-caption-0,
    .video-caption-2 {
      position: relative;
      cursor: pointer;
      width: 100%;
      img {
        width: 100%;
        height: var(--height-small-pictures);
      }
      margin: 2rem 0rem 2rem 0rem;
    }

    .video-caption-1 {
      position: relative;
      cursor: pointer;
      width: 100%;
      img {
        width: 100%;
        height: var(--height-small-pictures);
      }
      margin: 2rem 2rem 2rem 2rem;
    }
  }
}

.ago-video-caption-title {
  margin-top: 0.5rem;
}

.ago-button-video {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
