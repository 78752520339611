
.ago-use-cases-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 27.5rem;

  .description-container {
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    .ago-title {
      overflow: hidden;
      text-overflow: ellipsis;

      h2 {
        text-overflow: ellipsis;
      }
    }
  }
}
.ago-use-case-card-img {
  height: 18rem;
  width: 100%;
}

.uc_descrpition {
  margin-bottom: 1.5rem;
}
