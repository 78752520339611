@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.indicator-item {
    flex: 0 1 14rem;
    position: relative; 
    display: flex;        
    flex-direction: column; 
    align-items: center; 
    text-align: center;  
    margin-bottom: 1rem;  
    
    .indicator-value {
      color: var(--nj-semantic-color-text-additional-signature-default);
      top: 1.60rem;
      position: relative;
      display: inline-block;
      z-index: 1;
    } 

    .indicator-line {
      height: 1rem;  
      background: linear-gradient(90deg, rgba(0,212,255,1) 30%, rgba(35,210,181,1) 100%);
      margin: 0.25rem 0;  
      width: 12rem;
      display: flex;
      align-self: flex-start;
    }
    .indicator-name {
      text-align: center;
      width: 90%;
      justify-content: center;
      color: var(--nj-semantic-color-text-additional-signature-default);
    }
  }