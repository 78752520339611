@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ppctaob-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.ppctaob-full-size-background {
    background-color: var(--nj-core-color-blue-600);
}

.ppctaob-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: var(--nj-core-color-blue-600);
    @include large-only {
        width: 80%;
    }

    @include small-and-medium-only {
        width: 90%;
    }
    
}

.ppctaob-layout {
    width: 80%;
    display: flex;
    flex-direction: row;
}

.ppctaob-text-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
}

.ppctaob-text-width-with-image {
    @include large-only {
        width: calc( 100% - 16.625rem );
    }
    @include small-and-medium-only {
        width: calc( 100% - 10rem );
    }
}

.ppctaob-image-layout {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ppctaob-image {
    @include large-only {
        padding-left: 2rem;
        size: 14.625rem;
    }
    @include small-and-medium-only {
        size: 10rem;
    }
}

.ppctaob-title {
    color: var(--nj-core-color-grey-0);
    text-align: start;
    margin-top: 4rem;
    margin-bottom: 1rem;
}

.ppctaob-description {
    color: var(--nj-core-color-grey-0);
    font-weight: lighter;
    margin-bottom: 2.5rem;
}

.ppctaob-button {
    width: fit-content;
    max-width: 100%;
}

.ppctaob-button-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.ppctaob-button-icon {
    height: 2rem;
}

.ppctaob-button-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:max-content;
}