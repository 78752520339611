.hec-event {
  height: fit-content !important;
  width: 25rem;
  min-width: 25rem !important;
  min-height: 25rem;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  padding: 0;
  .nj-card__body_Ef {
    padding-left: 1.5rem;
    padding-top: 1rem;
  }
}

.hec-event-container {
  margin-right: 2rem;
}

.hec-event .nj-card__img-wrapper {
  height: 12rem;
}

.hec-button-pos {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hec-space {
  color: #60798b;
  height: 2rem;
}

.hec-title {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  height: 4.5rem;
}
