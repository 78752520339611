@import '../../../../../../styles/fonts/responsivity/breakpoints.scss';
.he-block {
  @include large-only {
    width: 77%;
  }
  @include small-and-medium-only {
    width: 100%;
  }
}

.he-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.he-list::-webkit-scrollbar {
  display: none;
}
