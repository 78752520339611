@import '../../../../../styles/fonts/responsivity/breakpoints.scss';

.two-column {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 90%;
  margin: 2rem auto;

  h1 {
    color: var(--nj-semantic-color-text-additional-signature-default);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Lato;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;

    &__column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;

      &__full-size-image {
        width: 100%;
        height: 100%;
      }

      &__image-container {
        display: flex;

        img {
          width: 100%;
          height: auto;
        }
      }

      li > ul {
        padding-left: 1.5rem;
      }

      p {
        color: var(--nj-semantic-color-text-neutral-primary-default);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }

      .raw-html-embed + .raw-html-embed:not(:last-child) {
        margin: 0 0 2rem 0;
      }

      .raw-html-embed + .raw-html-embed:last-child {
        margin: 0;
      }

      .raw-html-embed:last-child {
        margin: 2rem 0 0 0;
      }

      .raw-html-embed:not(:last-child) {
        margin: 2rem 0;
      }
    }
  }

  @include small-and-medium-only {
    margin: 2rem;
  }
}
