@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ppcta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;
}

.ppcta-title {
  color: var(--nj-semantic-color-text-additional-signature-default);
  text-align: center;

  font-family: var(--nj-semantic-font-family-default);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: var(--nj-semantic-line-height-heading-2xl);
  letter-spacing: var(--nj-semantic-letter-spacing-none);
}

.ppcta-btns {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  padding-top: 2.5rem;
}
