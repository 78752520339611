@import '../../../../../styles/fonts/responsivity/breakpoints.scss';

.mpp-display-List {
  flex-direction: row-reverse;
  @include large-only {
    display: flex;
  }
  @include small-and-medium-only {
    display: none;
  }
}

.mpp-display-Selector {
  @include large-only {
    display: none;
  }
  @include small-and-medium-only {
    display: flex;
  }
}

.mpp-sticky {
  position: fixed;
  top: 0;
  z-index: 1;
  padding-top: 6rem;
  width: 25%;
}

