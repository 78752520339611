.author-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.author-container-labels-values {
  display: flex;
  justify-content: space-between;
}

.label-value-pair {
  display: flex;
  flex-direction: column;
}
.label-value-pair p {
  margin: 0;
}

.ago-detail-lable {
  font-size: 0.625rem;
}