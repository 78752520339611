@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
.hob-background {
  background-position: right bottom !important;
  background-size: cover !important;
  --background-gradient-translucent-color: #ffffff00;
  --background-gradient-full-color: rgba(255, 255, 255, 0.99);
  transition: all 100ms ease-in;
  @include large-only {
    background: linear-gradient(
        270deg,
        var(--background-gradient-translucent-color) 0%,
        var(--background-gradient-full-color) 89.93%
      ),
      var(--header-odt-container-background) center;
  }
  @include small-and-medium-only {
    background: linear-gradient(
        270deg,
        var(--background-gradient-translucent-color) 0%,
        var(--background-gradient-full-color) 89.93%
      ),
      var(--header-odt-small-container-background) center;
  }
}

.hob-layout {
  height: 18.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 6rem;
}

.hob-title {
  width: 60%;
}

.hob-subtitle {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  width: 60%;
  color: var(--nj-core-color-grey-800);
}

.hob-end {
  height: 0.5rem;
  background-color: var(--nj-core-color-blue-600);
  width: 6rem;
}
