.ago-banner-content {
  padding-top: var(--nj-semantic-size-spacing-16);
  padding-bottom: var(--nj-semantic-size-spacing-16);
  padding-left: 10rem;
  padding-right: 10rem;
  justify-content: center;
  gap: var(--nj-semantic-size-spacing-16);
  align-self: stretch;
  list-style: none;
  width: max-content;
}
