@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.indicators_odt_container {
  background: #F6F8F9;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 7rem;
  align-items: center;
}

.indicators_odt_layout {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.indicators_odt_layout_small_only {
  justify-content: center;
  gap: 3rem;
}

.indicators_odt_title {
  width: 80%;
  color: #182663;
  text-align: center;
}

.indicators_odt_rich-text {
  width: 80%;
  margin-top: 2rem;
}
