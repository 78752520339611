/* @tailwind base; * */

@tailwind utilities;

@tailwind components;

body,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

.flex-basis {
  @apply flex-grow;
  flex-basis: 0;
}

.w-80 {
  width: 20rem;
}

.z-30 {
  z-index: 30;
}

.text-right {
  text-align: right;
}

.border-blue-700 {
  border-color: #2b6cb0;
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.text-black {
  color: black;
}

.pt-16 {
  padding-top: 4rem;
}

.text-gray-a8a8a8 {
  color: #a8a8a8;
}

.bg-gray-f5f5f5 {
  background-color: #f5f5f5;
}

.border-gray-c6c6c6 {
  border-color: #c6c6c6;
}

.border-gray-f5f5f5 {
  border-color: #f5f5f5;
}

.list-style-disc {
  list-style: disc;
}

.list-inside {
  list-style-position: inside;
}

.leading-relaxed {
  line-height: 1.625;
}

.bg-white {
  background-color: white;
}

.text-neutral-9 {
  color: hsl(0, 0%, 33%);
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.py-0-i {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.data4uContentStyle p {
  margin-bottom: 1rem;
}

.data4uContentStyle a,
.data4uContentStyle1 a {
  color: #2b6cb0;
  text-decoration: underline;
}

.data4uContentStyle1 .no-underline {
  text-decoration: none;
}

.data4uContentStyle ul,
.data4uContentStyle1 ul {
  margin-left: 1rem;

  list-style: disc;
}
.data4uContentStyle2 ul {
  margin-left: 1rem;
  margin-top: 1rem;
  list-style: disc;
}
.data4uContentStyle2 li {
  margin-bottom: 0;
  margin-left: 0.4rem;
}

.data4uContentStyle li {
  margin-bottom: 0.5rem;
}
.data4uContentStyle1 li {
  margin-left: 15px;
}

.data4uContentStylet li {
  margin-left: 15px;
}
.dataContentStylet {
  white-space: break-spaces;
}
.dataContentStylet ul {
  list-style: disc;
  padding-left: 1rem;
}
.dataContentStylet li {
  padding-bottom: 0.6rem;
}

.data4uContentStyle table,
.data4uContentStyle figure,
.data4uContentStyle1 table,
.data4uContentStyle1 figure {
  margin: 0 auto 2rem;
}

.data4uContentStyle tr:nth-child(even),
.data4uContentStyle1 tr:nth-child(even) {
  background-color: #f5f5f5;
}

.data4uContentStyle td,
.data4uContentStyle th,
.data4uContentStyle1 td,
.data4uContentStyle1 th {
  padding: 1rem;
}

.h-14 {
  height: 3.5rem;
}

.border-b-1 {
  border-bottom-width: 1px;
}
.text-xs2 {
  font-size: 10px;
}

.normal-disc-list ul {
  list-style: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 2.5em;
}
