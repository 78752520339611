.data-domain {
  display: flex;
  flex-direction: row;
  position: relative;

  &:is(:nth-child(2)) {
    margin-top: 4rem;
    scroll-margin-top: 4rem;
  }

  &:not(:nth-child(2)) {
    margin-top: 7rem;
    scroll-margin-top: 7rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &__image {
    position: absolute;

    &--inverted {
      right: 0;
    }

    @media screen and (max-width: 768px) {
      height: calc(100% - 6rem);
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      height: 100%;
      width: 35rem;
    }

    @media screen and (min-width: 1300px) {
      height: 100%;
      width: 35rem;
    }
  }

  &__body {
    box-sizing: border-box;
    position: relative;
    width: 100%;

    &__container {
      display: flex;
      width: 100%;
      height: 100%;
      padding: var(--nj-semantic-size-spacing-48);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--nj-semantic-size-spacing-24);
      background: #fff;
      box-sizing: border-box;

      &__attachements {
        display: flex;
        padding: var(--nj-semantic-size-spacing-24) var(--nj-semantic-size-spacing-48);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.625rem;
        width: 100%;
        max-width: 25rem;
        border-radius: var(--nj-semantic-size-border-radius-md);
        background: var(--nj-semantic-color-background-brand-solid-default);
        margin: 2rem auto 0 auto;
        box-sizing: border-box;

        &__item {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          cursor: pointer;

          &__icon {
            color: white;
          }

          p {
            color: var(--nj-semantic-color-text-neutral-inverse-default);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Lato;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;
            text-decoration: underline;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 3rem 2.563rem 0 2.563rem;
      margin: 0 auto;
    }

    @media screen and (min-width: 768px) {
      padding: 3rem 0 3rem 11.375rem;

      &--inverted {
        padding: 3rem 11.375rem 3rem 0;
      }
    }

    @media screen and (min-width: 1300px) {
      padding: 3rem 0 3rem 26.875rem;

      &--inverted {
        padding: 3rem 26.875rem 3rem 0;
      }
    }
  }

  .data-domain-large-margin {
    @media screen and (max-width: 768px) {
      margin: 0 3rem;
    }
  
    @media screen and (min-width: 768px) {
      margin: 0 3rem;
    }
  
    @media screen and (min-width: 1300px) {
      margin: 0 12rem;
    }
  }
}
