.mppla-expand-icon {
    margin-top: 0.25rem;
    margin-right: 0.5rem;
    transition: transform 0.5s ease;
    cursor: pointer;
}

.mppla-icon-normal {
    transform: rotate(0deg);
}

.mppla-icon-reverse {
    transform: rotate(180deg);
}
