@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.page-program-full-size {
    height: auto;
}

* {
    box-sizing: border-box;
}

.ppl-layout {
    display: flex;
    @include large-only {
        flex-direction: row;
    }

    @include small-and-medium-only {
        flex-direction: column;
    }
}

.ppl-navigation {
    @include large-only {
        width: 25%;
        height: 40rem;
    }
}

.ppl-content {
    @include large-only {
        width: 75%;
    }
}