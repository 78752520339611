@import '../../../../styles/fonts/responsivity/breakpoints.scss';


.pptart-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
}

.pptart-layout {
  @include medium-and-large-only {
    width: 80%;
  }

  @include small-only {
    width: 90%;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.pptart-title {
  width: 100%;
  color: var(--nj-core-color-ultramarine-900);
  text-align: center;
  margin-bottom: 2rem;
}

.pptart-text {
  width: 100%;
}