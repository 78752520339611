@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.ppkob-block {
    display: flex;
    flex-direction: column;

    @include large-only {
        margin: 4rem 12rem;
    }

    @include small-and-medium-only {
        margin: 4rem 0;
    }


}
.ppkob-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ppkob-title {
    width: 100%;
    color: #182663;
    text-align: center;
}

.iodb-description {
    margin-top: 3rem;
}

.ppkob-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ppkob-section {
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    @include small-and-medium-only {
        display: flex;
        flex-wrap: wrap;
    }
}