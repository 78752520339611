.book-viewer-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin: 4rem 2rem;
}

.book-viewer {
  display: flex;
  max-width: 100%;
  height: fit-content;
  margin: 2rem 2rem;

  &__button {
    padding: 0.5rem;
  }
}

.book-viewer-fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
