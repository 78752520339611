@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.rttb-to-tp-button {
  cursor: pointer;
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 999;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background-color: #182663CC;
  color: var(--nj-core-color-grey-0);
}