.ago-breadcrumb-layout {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ago-breadcrumb-icon span {
  color: var(--nj-semantic-color-text-neutral-tertiary-default) !important;
}

.ago-breadcrumb-separator {
  margin-left: 0.3rem;
  margin-right: 0.5rem;
  color: var(--nj-semantic-color-text-neutral-tertiary-default);
}

.ago-breadcrumb-path {
  color: var(--nj-semantic-color-text-neutral-primary-default);
  font-weight: bold;
}

.ago-breadcrumb-path-link {
  text-decoration: underline;
  color: var(--nj-core-color-grey-600);
  :hover {
    cursor: pointer;
  }
}
