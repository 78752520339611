@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.pptoc-tool-tall {
    flex: 0 1 30%;
    margin-bottom: 4rem;
    max-width: 17rem;
}

.pptoc-tool-small {
    flex: 0 1 18%;
    margin-bottom: 4rem;
}

.pptoc-tool-icon img {
    width: auto;
    height: 3rem;
}

.pptoc-text-tall {
    height: 21.5rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.pptoc-text-short {
    height: 5rem;
}

.pptoc-tool-title {
    color: var(--nj-core-color-grey-0);
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-shrink: 0
}

.pptoc-tool-description {
    color: var(--nj-core-color-grey-0);
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 1.5rem;
}

.pptoc-tool-description::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
}

.pptoc-tool-description::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.625rem;
}

.pptoc-tool-description::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.pptoc-tool-description::-webkit-scrollbar-track {
    background-color: transparent;
}
.pptoc-tool-button {
    width: 100%;
}
  