.data-domains-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:is(:nth-child(2)) {
    margin-top: 4rem;
  }

  &:not(:nth-child(2)) {
    margin-top: 7rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &__header {
    h1 {
      color: var(--nj-semantic-color-text-additional-signature-default);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Lato;
      font-size: 3.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem;
    }

    p {
      margin: 3rem 0 1.5rem 0;
      color: var(--nj-semantic-color-text-neutral-primary-default);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Lato;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      width: 100%;
    }

    @media screen and (min-width: 1300px) {
      width: 71rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
      text-align: center;
      color: var(--nj-semantic-color-text-neutral-primary-default);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Lato;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
    }

    &__domains-list {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      width: fit-content;
      max-width: 100%;
      margin: 1.5rem auto 0 auto;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 0.313rem;
      }

      &::-webkit-scrollbar-track {
        background: none;
      }

      &::-webkit-scrollbar-thumb {
        background: #b0b0b09c;
        border-radius: 0.625rem;

        &:hover {
          background: #9595959c;
        }
      }

      &__domain {
        max-width: 12.5rem;
        min-width: 12.5rem;
        max-height: 12.5rem;
        min-height: 12.5rem;
        margin-bottom: 0.625rem;
      }
    }
  }
  
  .data-domains-list-large-margin {
    @media screen and (max-width: 768px) {
      margin: 0 3rem;
    }
  
    @media screen and (min-width: 768px) {
      margin: 0 3rem;
    }
  
    @media screen and (min-width: 1300px) {
      margin: 0 12rem;
    }
  }
}
