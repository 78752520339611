@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
.root {
  scroll-behavior: smooth;
}
.ago-news-container {
  --background-gradient-translucent-color: #d9d9d980;
  --background-gradient-full-color: rgba(255, 255, 255, 0.99);
  transition: all 100ms ease-in;
  background: linear-gradient(
      180deg,
      var(--background-gradient-translucent-color) 0%,
      white 97%
    ),
    var(--ago-news-container-background) center;
  background-position: center;
  background-size: cover;

  &.test {
    animation: backgroundTransition 0.5s ease 1 reverse forwards;

    @keyframes backgroundTransition {
      0% {
        background-position: center;
        background-size: 105%;
      }

      100% {
        background-position: center;
        background-size: 100%;
      }
    }
  }

  .band {
    background-color: #e01b78;
    padding: var(--nj-semantic-size-spacing-16);

    p {
      color: var(--Neutral-colors-White, #fff);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Lato;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 85%;
    z-index: 1;

    .welcome-text,
    .news-list {
      margin: 10rem 6rem 2rem 6rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--nj-semantic-size-spacing-16);

      h1 {
        color: var(--light-blue-blue-900, #0b2d4e);
      }
    }

    .markdown {
      a {
        text-decoration: underline;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .news-list {
      .news {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        height: 200px;

        .ago-picture-container {
          min-width: 320px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .ago-snippet-container {
          width: 350px;
          height: 180px;
          padding: 1.5rem;
          background-color: var(--nj-semantic-color-background-neutral-primary-default);

          h3 {
            display: block;
            display: -webkit-box;
            max-width: 300px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .ago-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    position: relative;
    z-index: 0;
  }
}

.arrow-button {
  cursor: pointer;
}

.arrow-button-border {
  position: absolute;
  top: 40%;
  left: 50%;
  height: 3.5rem;
  width: 3.5rem;
  transform: translate(-50%, -50%);
  border: #0b2d4e solid 0.15rem;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 250ms;

  &:hover {
    height: 5rem;
    width: 5rem;
  }
}

.arrow-button {
  color: #0b2d4e !important;
}

@include small-and-medium-only {
  .ago-news-container {
    .content {
      flex-direction: column;
      justify-content: center;

      .welcome-text {
        h1,
        p {
          margin-left: 10dvw;
          margin-right: 10dvw;
          text-align: center;
        }
      }

      .welcome-text,
      .news-list {
        margin: 7.5rem 7.5rem 3.5rem 7.5rem;
        width: auto;
        display: flex;
        flex-direction: column;
      }
    }

    .ago-arrow-container {
      display: none;
    }
  }
}
