.related-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 2rem auto;
  gap: 3rem;

  &__list {
    display: flex;
    gap: 1.5rem;
    overflow: auto;
    max-width: 100%;
  }
}
