.ago-yammer-title {
  margin-bottom: 1.5rem;
}

.ago-yammer-widget {
  border: 0px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 4.313rem);
}
