@import '../../../../../../styles/fonts/responsivity/breakpoints.scss';
.htc-tile-container {
  margin-bottom: 0.5rem;
  flex: 1;
  @include small-and-medium-only {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.htc-tile {
  height: 100%;
  min-height: 100% !important;
    div div {
      h4 {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      span {
        margin-bottom: 0.5rem;
      }
    }
}

.htc-title {
  color: white;
  margin-top: 0;
  margin-bottom: 1rem;
}

.htc-icon {
  .material-icons {
    color: white !important;
  }
}
