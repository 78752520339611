@import '../../../../../styles/fonts/responsivity/breakpoints.scss' ;
.ago-use-cases-homepage {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 17%,
      #0b2d4e 17%,
      #0b2d4e 100%);
  padding: 0 14.8rem ;
  height: 100%;

  @include small-and-medium-only {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 12.5%,
        #0b2d4e 12.5%,
        #0b2d4e 100%);

    @include small-only {
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 11%,
          #0b2d4e 11%,
          #0b2d4e 100%);
    }

    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  h3 {
    margin-bottom: 1.5rem;
    font-weight: 1.5rem;
  }
}

.ago-usecases-title {
  padding-left: 1.3rem;
  @include small-and-medium-only  {
    padding-left: 2rem;
  }
}

.ago-last-usecases-flex {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 1.5rem;

  @include small-and-medium-only  {
    overflow-x: scroll;
    scrollbar-width: none;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @include large-only {
    justify-content: center;
  }
  div {
    border: none;
  }
}

.ago-bottom-button {
  display: flex;
  flex-direction: row-reverse;
  padding: 2rem 1.3rem;

  @include small-and-medium-only  {
    padding: 2rem 2rem 2rem 0;
  }
}