@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.pptob-layout {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    background-color: var(--nj-core-color-blue-600);

    @include large-only {
        padding: 0rem 12rem;
    }

    @include medium-only {
        padding: 0rem 6rem;
    }

    @include small-only {
        padding: 0rem 3rem;
    }
}

.pptob-title {
    color: var(--nj-core-color-grey-0);
    text-align: center;
    margin-top: 4rem;
}

.pptob-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pptob-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.pptob-section-type-tall {
    justify-content: space-around;
}

.pptob-section-type-small {
    justify-content: center;
    gap: 9%;
}
  