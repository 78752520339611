@import '../../../../styles/fonts/responsivity/breakpoints.scss' ;
.hit-backgroud {
  @include large-only {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 6rem;
    margin-right: 6rem;
  }
  @include medium-only {
    margin: 2rem;
  }
  @include small-only {
    margin: 2rem;
  }
  background: #F6F8F9;
  border: 0.06rem solid #ccd4da;
}

.hit-layout {
  background: white;
  display: flex;
  @include large-only {
    padding: 2rem;
    flex-direction: row;
  }
  @include medium-only {
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 2rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @include small-only {
    padding: 2rem;
    flex-direction: column;
  }
  justify-content: space-between;
}

.hit-row {
  @include large-only {
    width: 47%;
  }
  @include small-and-medium-only {
    width: 100%;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
