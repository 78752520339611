@import '../../../../styles/fonts/responsivity/breakpoints.scss';

.rich-text-custom {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__full-size-image {
    width: 100%;
    height: 100%;
  }

  &__image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    img {
      height: auto;
    }
  }

  .raw-html-embed+.raw-html-embed:not(:last-child) {
    margin: 0 0 2rem 0;
  }

  .raw-html-embed+.raw-html-embed:last-child {
    margin: 0;
  }

  .raw-html-embed:last-child {
    margin: 2rem 0 0 0;
  }

  .raw-html-embed:not(:last-child) {
    margin: 2rem 0;
  }

  ul li {
    margin-left: 1.5rem;
  }
}