$width: 19rem;
$width-mobile: 26rem;
$height: 26rem;
$height-mobile: 35rem;
$padding: 3rem;

.related-product {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: $padding;
  width: $width;
  min-width: $width;
  height: $height;
  min-height: $height;
  box-sizing: border-box;
  background: no-repeat center right
    url('../../../../../public/images/blocks/related-products/logo.svg')
    var(--nj-semantic-color-background-additional-signature-default);
  transition: background 500ms ease-in-out;

  &:hover {
    background: no-repeat center right
      url('../../../../../public/images/blocks/related-products/logo-hover.svg')
      var(--nj-semantic-color-background-additional-signature-default);
  }

  @media screen and (min-width: 1920px) {
    width: $width-mobile;
    min-width: $width-mobile;
    height: $height-mobile;
    min-height: $height-mobile;
  }

  h2 {
    color: var(--nj-semantic-color-text-neutral-inverse-default);
    text-align: center;
    font-family: var(--nj-semantic-font-family-default);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: var(--nj-semantic-letter-spacing-none);
    overflow-wrap: break-word;
    cursor: default;
  }

  p {
    display: -webkit-box;
    position: absolute;
    max-width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: auto 0;
    padding: 0 $padding;
    color: var(--nj-semantic-color-text-neutral-inverse-default);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: var(--nj-semantic-letter-spacing-none);
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    cursor: default;
  }

  &__button {
    margin-top: auto;
  }
}
