.ppcdz-size {
  width: 100%;
}

.ppcdz-generic-markdown-link p {
  a {
    color: #42a5f5;
  }
  a:hover {
    text-decoration: underline;
  }
}
