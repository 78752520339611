.mppl-void {
    flex: 1;
}

.mppl-layout {
    width: max-content;
    flex: 3;
    align-self: flex-end;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 34rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.mppl-layou::-webkit-scrollbar {
    display: none;
}

.mppl-box {
    height: 4rem;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
}

.mppl-box-selected {
    background-color: #0F55E11A;
    font-weight: bold;
    border-right: 0.15rem solid var(--nj-core-color-blue-600);
}

.mppl-box-not-selected {
    border-right: 0.06rem solid var(--nj-core-color-grey-300);
}

.mppl-bold {
    font-weight: bold;
}

.mppl-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.mppl-offset-no-icon {
    padding-left: 2.5rem;
}

.mppl-offset-program {
    padding-left: 1rem;
}

.mppl-offset-project {
    padding-left: 2rem;
}

.mppl-offset-subproject {
    padding-left: 5.5rem;
}