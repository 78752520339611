@import '../../../../styles/fonts/responsivity/breakpoints.scss';


.ddopp-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.ddopp-layout {
    width: 80%;
    @include small-only {
      width: 90%; 
    }
    background-color: var(--nj-core-color-grey-0);
    padding: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ddopp-accordion {
    width: 100%;
    border-bottom: 0.06rem solid var(--nj-core-color-grey-300);
}

.ddopp-header {
    font-size: 3.375rem !important;
    color: var(--nj-core-color-ultramarine-900);
}

.ddopp-accordion-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}