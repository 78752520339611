@import '../../../../../styles/fonts/responsivity/breakpoints.scss';
.ago-image-card-tag {
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
  top: 25px;
  left: 25px;
  background-color: #fff;
  height: 1.5rem;

  ::first-letter {
    text-transform: uppercase;
  }
  p {
    margin-bottom: 0;
  }
}

@include small-and-medium-only {
  .ago-image-card-tag {
    position: absolute;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding: 0rem 1rem 0rem 1rem;
    text-align: center;
    top: 25px;
    left: 25px;
    background-color: #fff;
    height: 1.5rem;

    ::first-letter {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@include mid-large-only  {
  .ago-image-card-tag {
    position: absolute;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding: 0rem 1rem 0rem 1rem;
    text-align: center;
    top: 25px;
    left: 25px;
    background-color: #fff;
    height: 1.5rem;

    ::first-letter {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
    }
  }
}
@include extra-large-only {
  .ago-image-card-tag {
    position: absolute;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding: 0rem 1rem 0rem 1rem;
    text-align: center;
    top: 25px;
    left: 25px;
    background-color: #fff;
    height: 1.5rem;

    ::first-letter {
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
    }
  }
}
