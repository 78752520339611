.key-people {
  padding-top: 3rem;
  height: 100%;
  margin-top: 7rem;
  margin-bottom: 7rem;
  background: url('../../../../../public/images/key-people-background.svg') center center;
  background-size: cover;

  &__title {
    text-align: center;
    color: var(--nj-semantic-color-text-neutral-inverse-default);
  }

  &__section {
    width: 90%;
    max-width: 81.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5rem auto 0 auto;

    &__description {
      margin: 0 8rem 3rem 8rem;
      max-width: 100%;
      color: var(--nj-semantic-color-text-neutral-inverse-default);
      font-family: Lato;
      font-feature-settings: 'liga' off, 'clig' off;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;

      img {
        width: 100%;
        height: auto;
      }

      ul li {
        margin-left: 1.5rem;
      }
    }

    &__list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__separator {
      height: 0.125rem;
      width: 50%;
      margin: 0 auto;
      background: var(--nj-semantic-color-border-neutral-inverse-default);
    }
  }
}
