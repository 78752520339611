.hts-layout {
  width: 45%;
}

.hts-title {
  margin-bottom: 1rem;
}

.hts-tag div {
  margin: 0.25rem;
}
