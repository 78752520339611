.result-separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #1280bf;
}

.result-separator > .line {
  flex: 1;
  border-bottom: 2px solid #1280bf;
}

.result-separator .left-line {
  margin-right: 10px;
}
.result-separator .right-line {
  margin-left: 10px;
}

.card {
  width: 400px;
  height: 220px;
}
.card-title {
  width: 300px;
}

.card-rank {
  font-size: 25px;
  min-width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  border-radius: 50%;
  color: white;
  background-color: gray;
}

.card-rank.bg-rank-1 {
  background-color: #0b68a2;
}
.card-rank.bg-rank-2 {
  background-color: #1280bf;
}
.card-rank.bg-rank-3 {
  background-color: #1991d2;
}
.card-rank.bg-rank-4 {
  background-color: #2cb0ed;
}
.card-rank.bg-rank-5 {
  background-color: #40c3f7;
}

.shadow-around {
  -moz-box-shadow: 0 0 20px #ccc;
  -webkit-box-shadow: 0 0 20px #ccc;
  box-shadow: 0 0 20px #ccc;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
