.hucs-layout {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.hucs-title {
  margin-bottom: 1rem;
}

.hucs-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
}

.hucs-content p {
  margin-left: 0.25rem;
}
